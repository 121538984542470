import { Flex, useColorModeValue } from "@chakra-ui/react";
import CustomButton from "components/CustomButton/CustomButton";
import { MdSave } from "react-icons/md";

interface Props {
  saveListCallback: any;
}

const ContractInvoicesButtons: React.FC<Props> = ({
  saveListCallback,
}) => {
  const buttonColor = useColorModeValue("blue.700", "white");

  return (
    <Flex direction='row'>
      <CustomButton
        icon={MdSave}
        label='Save list'
        callback={() => saveListCallback()}
        color={buttonColor}
      />
    </Flex>
  );
}

export default ContractInvoicesButtons;