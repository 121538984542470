import {
  MdCalendarToday,
  MdChecklistRtl,
  MdOutlineSave,
  MdOutlineVisibility,
  MdPaid,
} from "react-icons/md";
import {
  ContractInvoiceStatusEnum,
  IContractInvoice,
} from "state/Invoices/Dto";
import {
  Badge,
  Flex,
  Icon,
  Td,
  Tr,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  format,
  lastDayOfMonth,
  getMonth,
  getYear,
  addMonths,
  getDay,
} from "date-fns";
import CustomButton from "components/CustomButton/CustomButton";
import { TimeHelper } from "logic/Time.helper";
import { BsPercent } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const ContractInvoicesListRow = (props: {
  contractId: string;
  invoice: IContractInvoice;
}) => {
  const markPaidButtonColor = useColorModeValue("green.500", "green.200");
  const viewButtonColor = useColorModeValue("blue.500", "blue.200");
  const percentButtonColor = useColorModeValue("blue.500", "blue.200");
  const savedIconColor = useColorModeValue("green.500", "green.200");
  const unsavedIconColor = useColorModeValue("red.500", "red.200");
  const timeHelper = new TimeHelper();
  const navigate = useNavigate();

  const addedMonthOnInvoice = addMonths(new Date(props.invoice.invoiceDate), 1);
  // console.log("addedMonthOnInvoice", addedMonthOnInvoice);
  const invoiceYear = getYear(props.invoice.invoiceDate);
  // const invoiceMonth = getMonth(theInvoiceDate);
  const invoiceYearWithPotentialIncrement = getYear(addedMonthOnInvoice);
  const theYear =
    invoiceYearWithPotentialIncrement > invoiceYear
      ? invoiceYear
      : invoiceYearWithPotentialIncrement;
  const theMonthWithIncrement = getMonth(addedMonthOnInvoice);
  const theMonth = theMonthWithIncrement === 0 ? 12 : theMonthWithIncrement;

  return (
    <Tr>
      {/* Description. */}
      <Td
        fontSize={{ sm: "14px" }}
        minW={{ sm: "150px", md: "200px", lg: "auto" }}
        borderColor="transparent"
      >
        <Flex direction="row" alignItems="center" gap={2}>
          <Icon
            as={MdCalendarToday}
            width="20px"
            height="20px"
            color="inherit"
          />
          <Flex direction="column">
            {/* Month and saved status. */}
            <Flex direction="row" alignItems="center" gap={2}>
              <Text fontSize="sm" fontWeight="500" color="black">
                {format(props.invoice.invoiceDate, "MMMM yyyy")}
              </Text>
              <Icon
                as={MdOutlineSave}
                width="15px"
                height="15px"
                color={
                  props.invoice.savedDate ? savedIconColor : unsavedIconColor
                }
              />
            </Flex>

            {/* The name of the beneficiary of current invoice. */}
            <Text fontSize="sm" fontWeight="500" color="secondaryGray.600">
              {props.invoice.for?.displayName || "Unknown"}
            </Text>
          </Flex>
        </Flex>
      </Td>

      {/* Invoiced date. */}
      <Td
        fontSize={{ sm: "14px" }}
        minW={{ sm: "150px", md: "200px", lg: "auto" }}
        borderColor="transparent"
      >
        {format(props.invoice.invoiceDate, "dd/MM/yyyy")}
      </Td>

      {/* Status */}
      <Td
        fontSize={{ sm: "14px" }}
        minW={{ sm: "150px", md: "200px", lg: "auto" }}
        borderColor="transparent"
      >
        {props.invoice.status === ContractInvoiceStatusEnum.CLOSED && (
          <Badge colorScheme="green">Closed</Badge>
        )}
        {props.invoice.status === ContractInvoiceStatusEnum.FINISHED && (
          <Badge colorScheme="blue">Finished</Badge>
        )}
        {props.invoice.status === ContractInvoiceStatusEnum.IN_PROGRESS && (
          <Badge colorScheme="orange">In progress</Badge>
        )}
        {props.invoice.status === ContractInvoiceStatusEnum.ATTENTION && (
          <Badge colorScheme="red">Attention</Badge>
        )}
      </Td>

      {/* Invoiced time. */}
      <Td
        fontSize={{ sm: "14px" }}
        minW={{ sm: "150px", md: "200px", lg: "auto" }}
        borderColor="transparent"
      >
        {timeHelper.humanize(props.invoice.timeSpent)}
      </Td>

      {/* Amount. */}
      <Td
        fontSize={{ sm: "14px" }}
        minW={{ sm: "150px", md: "200px", lg: "auto" }}
        borderColor="transparent"
      >
        {props.invoice.amount.toLocaleString("de-DE", {
          style: "currency",
          currency: "EUR",
        })}
      </Td>
      <Td
        fontSize={{ sm: "14px" }}
        minW={{ sm: "150px", md: "200px", lg: "auto" }}
        borderColor="transparent"
      >
        <Flex direction="row" justifyContent="center" gap="2">
          {/*  */}
          <CustomButton
            callback={() => console.log("not implemented yet")}
            color={markPaidButtonColor}
            icon={MdPaid}
          />
          {/* Show list of tasks performed. */}
          <CustomButton
            callback={() =>
              navigate(
                `/invoices/${props.contractId}/${theYear}/${
                  theMonth === 0 ? 12 : theMonth
                }`
              )
            }
            color={viewButtonColor}
            icon={MdChecklistRtl}
          />
          {/* Show invoice. */}
          <CustomButton
            callback={() => console.log("not implemented yet")}
            color={viewButtonColor}
            icon={MdOutlineVisibility}
          />
          {/* Apply reduction. */}
          <CustomButton
            callback={() => console.log("not implemented yet")}
            color={percentButtonColor}
            icon={BsPercent}
          />
        </Flex>
      </Td>
    </Tr>
  );
};

export default ContractInvoicesListRow;
