// Chakra imports
import { Button, Flex, FormControl, SimpleGrid, Skeleton, Stack, Text, useColorModeValue, useToast } from '@chakra-ui/react';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { useState } from 'react';
import { useAuth } from 'state/useAuthState';
import { IUserProfile } from 'state/Users/UsersService';

export default function PersonalInfoSettings() {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';
  const toast = useToast();
  const { updateUser, personalData } = useAuth();
  // Holds the field values.
  const [fields, setFields] = useState<IUserProfile>({
    uid: personalData?.uid || '',
    displayName: personalData?.displayName || '',
    firstName: personalData?.firstName || '',
    lastName: personalData?.lastName || '',
    email: personalData?.email || '',
    businessName: personalData?.businessName || '',
    businessLocation: personalData?.businessLocation || '',
    quantifier: null,
    userId: personalData?.userId || '',
    jobName: personalData?.jobName || '',
    bio: personalData?.bio || '',
    role: personalData?.role || '',
  });
  // Update other personal information.
  const updatePersonalData = () => {
    // Collect the data into the required object format (IUserProfile).
    console.log('updating personal data:', fields);
    updateUser(fields)
      .then(() => toast({
        title: 'Info updated!',
        description: "Profile information changes were applied.",
        status: 'success',
        duration: 9000,
        isClosable: true,
      }))
      .catch(() => toast({
        title: 'Update failed!',
        description: "Profile information changes were not applied.",
        status: 'error',
        duration: 9000,
        isClosable: true,
      }));
  }

  if (!personalData) {
    return (
      <Stack>
        <Skeleton height='20px' />
        <Skeleton height='20px' />
        <Skeleton height='20px' />
      </Stack>
    )
  }

  return (
    <FormControl>
      <Card>
        <Flex direction='column' mb='40px' ms='10px'>
          <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
            Personal Information
          </Text>
          <Text fontSize='md' color={textColorSecondary}>
            Here you can change you personal user account information
          </Text>
        </Flex>
        <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={{ base: '20px', xl: '20px' }}>
          
          {/* Display name field. */}
          <InputField type='string' mb='25px' id='displayName' label='Display name' placeholder='@john123' value={fields.displayName} onChange={(e: any) => setFields({ ...fields, displayName: e.target.value })} />
          {/* Email field (disabled). */}
          <InputField type='string' disabled={true} mb='25px' id='email' label='Email Address' placeholder='mail@example.com' value={fields.email} onChange={(e: any) => setFields({ ...fields, email: e.target.value })} />
          {/* Firstname field. */}
          <InputField type='string' mb='25px' id='firstName' label='First Name' placeholder='John' value={fields.firstName} onChange={(e: any) => setFields({ ...fields, firstName: e.target.value })} />
          {/* Lastname field. */}
          <InputField type='string' mb='25px' id='lastName' label='Last Name' placeholder='Doe' value={fields.lastName} onChange={(e: any) => setFields({ ...fields, lastName: e.target.value })} />

        </SimpleGrid>

        {/* Job title field. */}
        <InputField type='string' id='job' label='Job' placeholder='Web Developer' value={fields.jobName} onChange={(e: any) => setFields({ ...fields, jobName: e.target.value })} />
        {/* Biography field. */}
        <TextField
          id='about'
          label='About Me'
          h='100px'
          placeholder='Tell something about yourself in 150 characters!'
          value={fields.bio}
          onChange={(e: any) => setFields({ ...fields, bio: e.target.value })}
        />

        <Button variant='brand' minW='183px' fontSize='sm' fontWeight='500' ms='auto' onClick={() => updatePersonalData()}>
          Save changes
        </Button>
      </Card>
    </FormControl>
  );
}
