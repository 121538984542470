// Chakra imports
import Card from "components/card/Card";
import { startOfMonth, getDay, getMonth, getYear, addMonths } from "date-fns";
import { TasksHelper } from "logic/tasks.helper";
import { TimeHelper } from "logic/Time.helper";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IContract } from "state/Contracts/ContractsService";
import { IContractInvoice } from "state/Invoices/Dto";
import { invoicesService } from "state/Invoices/InvoicesService";
import { IEntry } from "state/Tasks/TasksDTO";
import { TasksStore } from "state/Tasks/TasksStore";
import { useAuth } from "state/useAuthState";
import { useContracts } from "state/useContractsState";

import { Box, Flex, SimpleGrid, Skeleton, Stack } from "@chakra-ui/react";

import InvoiceTasksList from "../components/ContractInvoiceTasksList/InvoiceTasksList";

export interface IInvoicePeriod {
  month: number;
  year: number;
}

export default function InvoiceTasks() {
  // Get the list of tasks for the selected date.
  const [tasks, setTasks] = useState<IEntry[]>([]);
  // Get the contract id for which we are getting the tasks
  const { contractId, month, year } = useParams();
  const { allContracts } = useContracts();
  const [contract, setContract] = useState<IContract>(null);
  const [invoiceData, setInvoiceData] = useState<IContractInvoice>(null);
  const [period, setPeriod] = useState<IInvoicePeriod>(null);
  const { usersDisplaynames, personalData } = useAuth();
  const tasksStore = new TasksStore();
  const timeHelper = new TimeHelper();
  const tasksHelper = new TasksHelper();

  /**
   * Get the required invoice data.
   */
  const getInvoice = useCallback(async () => {
    if (!period || !contract || !tasks.length) {
      return;
    }
    const allInvoices = await invoicesService.getInvoices(
      contract,
      usersDisplaynames,
      personalData.userId,
      tasks
    );
    // Find the currently required invoice.

    const currentInvoice = allInvoices.find((invoice: IContractInvoice) => {
      const normalizedDate = new Date(invoice.invoiceDate);
      const firstDayOfMonth = startOfMonth(normalizedDate);
      const period = {
        month: getMonth(firstDayOfMonth),
        year: getYear(firstDayOfMonth),
      };
      return period.year === period.year && period.month === period.month;
    });
    console.log("currentInvoice", currentInvoice);
    if (currentInvoice) {
      setInvoiceData(currentInvoice);
    }
  }, [usersDisplaynames, personalData, tasks, period, contract]);

  /**
   * Get all the tasks for the provided
   * date period.
   *
   * @param date Date
   * @returns void
   */
  const getTasksForDate = useCallback(
    async (date: Date) => {
      // Get the start and end dates for the current tasks period.
      const period = timeHelper.getPeriodForProvidedDate(date);
      const allTasks = await tasksStore.getTasksByContractForPeriod(
        contractId,
        period[0],
        period[1]
      );
      const tasksWithTotalTimeSpent =
        tasksHelper.cumulateTodoMinutesForTasks(allTasks);
      setTasks(tasksWithTotalTimeSpent);
      // Find the current contract.
      const foundContract = allContracts.find(
        (c: IContract) => c.id === contractId
      );
      setContract(foundContract);
    },
    [contractId, month, year, allContracts]
  );

  /**
   * Get the required invoice so we can generate
   * the link for the PDF generation.
   */
  useEffect(() => {
    if (!contract) return;
    getInvoice();
  }, [contract]);

  /**
   * Get the list of tasks for the provided
   * contractId and month.
   */
  useEffect(() => {
    if (!contractId || !month || !year) return;
    setPeriod({ month: parseInt(month), year: parseInt(year) });
    console.log("month", month);
    console.log("year", year);
    getTasksForDate(new Date(`${year}-${month}-1`));
  }, [contractId, month, year]);

  if (!contract) {
    return <></>;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <Flex direction="column">
          {!invoiceData && (
            <Card>
              <Stack>
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
              </Stack>
            </Card>
          )}
          {!!invoiceData && (
            <InvoiceTasksList
              contractId={contract.id}
              tasks={tasks}
              invoice={invoiceData}
              period={period}
            />
          )}
        </Flex>
      </SimpleGrid>
    </Box>
  );
}
