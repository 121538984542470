import { PDFViewer } from "@react-pdf/renderer";
import { useParams } from "react-router-dom";
import { useContracts } from "state/useContractsState";
import { InvoiceAsPdf } from "./InvoiceTasksAsPdf";

export const RenderedTasksListForPdf = () => {
  // Get the contract id for which we are getting the tasks
  const { contractId, month, year } = useParams();
  const { allContracts } = useContracts();
  
  return (
    <div className="App">
      <PDFViewer>
        <></>
      </PDFViewer>
    </div>
  )
};
