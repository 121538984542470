import { endOfMonth, startOfMonth } from "date-fns";
import ContractHelper from "logic/contract.helper";
import { TasksHelper } from "logic/tasks.helper";
import { IContract } from "state/Contracts/ContractsService";
import { IEntry } from "state/Tasks/TasksDTO";
import { IUserDisplayname } from "state/useAuthState";
import { IContractInvoice, IContractInvoicesContainer } from "./Dto";
import { invoiceGenerator } from "./InvoiceGenerator";
import { InvoicesDb } from "./InvoicesDb";

class InvoicesService extends InvoicesDb {
  private tasksHelper = new TasksHelper();
  private contractHelper = new ContractHelper();

  constructor() {
    super();
  }

  /**
   * > Remove the invoice from the currently running month
   * @param {string} contractId - The id of the contract that the invoices are for.
   * @param {IContractInvoice[]} invoices - IContractInvoice[]
   * @returns a promise.
   */
  public async saveInvoices(
    contractId: string,
    invoices: IContractInvoice[]
  ): Promise<any> {
    // Remove the invoice from the currently running month.
    const recentRemoved = invoices.filter(
      (i: IContractInvoice) =>
        i.invoiceDate !== endOfMonth(new Date()).getTime()
    );
    return await this.addInvoicesForContract(contractId, recentRemoved);
  }

  /**
   * It fetches the invoices from the database and then fills in the missing ones
   * @param {IContract} contract - The contract for which the invoices are generated.
   * @param {IUserDisplayname[]} displayNames - A list of all users in the system.
   * @param {string} currentUserId - The id of the user who is currently logged in.
   * @param {IEntry[]} tasks - The tasks that are associated with the contract.
   * @param {number} [applyReduction] - This is a number that will be subtracted from the total amount
   * of the invoice.
   * @returns An array of IContractInvoice objects.
   */
  public async getInvoices(
    contract: IContract,
    displayNames: IUserDisplayname[],
    currentUserId: string,
    tasks: IEntry[],
    applyReduction?: number
  ): Promise<IContractInvoice[]> {
    // First fetch the invoices stored in the db.
    const invoicesFromDb = await this.getAllInvoicesForContractFromDb(
      contract.id
    );
    /**
     * Make a list of invoices with the ones from
     * db and generate the ones that weren't saved
     * yet.
     */
    return this.getInvoicesAndFillMissing(
      contract,
      displayNames,
      currentUserId,
      tasks,
      invoicesFromDb,
      applyReduction
    );
  }

  /**
   * It takes a list of tasks and a list of invoices and returns a list of invoices with the missing
   * months filled in
   * @param {IContract} contract - IContract - The contract that the invoices are for.
   * @param {IUserDisplayname[]} displayNames - IUserDisplayname[]
   * @param {string} currentUserId - string,
   * @param {IEntry[]} tasks - IEntry[]
   * @param {IContractInvoice[]} existingInvoices - IContractInvoice[]
   * @returns An array of invoices.
   */
  public getInvoicesAndFillMissing(
    contract: IContract,
    displayNames: IUserDisplayname[],
    currentUserId: string,
    tasks: IEntry[],
    existingInvoices: IContractInvoice[],
    applyReduction?: number
  ): IContractInvoice[] {
    // Parse through the list of tasks and get the months.
    const filteredByContract = this.tasksHelper.filterTasksByContracts(tasks, [
      contract.id,
    ]);
    const months = this.tasksHelper.getListOfDatesFromTasks(filteredByContract);
    const alreadySavedMonths = existingInvoices.map((i: IContractInvoice) =>
      startOfMonth(i.invoiceDate)
    );
    // Check which months are missing from the invoices until today.
    // const hasMissing = months.length > existingInvoices.length;
    // Get a list of the missing months.
    const missingMonths = months.filter(
      (m: Date) =>
        !!!alreadySavedMonths.find(
          (sm: Date) => sm.toDateString() === m.toDateString()
        )
    );
    // Generate the missing months.
    const generatedInvoices = invoiceGenerator.generateInvoices(
      contract,
      tasks,
      displayNames,
      currentUserId,
      missingMonths,
      applyReduction
    );
    const allInvoices = [...generatedInvoices, ...existingInvoices];
    // Sort the list of invoices.
    return allInvoices.sort((a: IContractInvoice, b: IContractInvoice) => {
      if (a.invoiceDate < b.invoiceDate) return 1;
      if (a.invoiceDate > b.invoiceDate) return -1;
      return 0;
    });
  }

  /**
   * Check if there is already a container for the current contract. If there is, return the invoices,
   * otherwise return an empty array.
   * @param {string} contractId - The id of the contract that we want to get the invoices for.
   * @returns An array of IContractInvoice objects.
   */
  public async getAllInvoicesForContractFromDb(
    contractId: string
  ): Promise<IContractInvoice[]> {
    // Check if there is already a container for the current contract.
    const container = await this.getContractInvoicesContainerByContractId(
      contractId
    );
    return container && !!container.invoices?.length ? container.invoices : [];
  }
}

export const invoicesService = new InvoicesService();
