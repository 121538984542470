import { IToast } from "components/Toast/Toast";
import { endOfMonth, startOfMonth } from "date-fns";
import {
  onSnapshot,
  QuerySnapshot,
  DocumentData,
  Unsubscribe,
} from "firebase/firestore";
import { TasksHelper } from "logic/tasks.helper";
import { TypeHelper } from "logic/type.helper";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { IContract } from "./Contracts/ContractsService";
import { preferenceService } from "./LocalStorage/preference.service";
import { TasksDB } from "./Tasks/TasksDb";
import { IEntry, IEntryTodo } from "./Tasks/TasksDTO";
import { TasksStore } from "./Tasks/TasksStore";
import { useAuth } from "./useAuthState";
import { useContracts } from "./useContractsState";

interface Props {
  children: JSX.Element;
}

export interface IAppContext {
  currentDate: Date;
  setCurrentDate: any;
  getTasksForCurrentDate: () => IEntry[];
  tasksForSelectedDate: IEntry[];
  allTasks: IEntry[];
  tasksBySelectedContract: IEntry[];
  markTask: (taskId: string, completed: boolean) => void;
  addTask: (task: IEntry) => void;
  editTask: (task: IEntry) => void;
  deleteTask: (taskId: string) => void;
  /**
   * Update the time spent on the provided
   * task.
   *
   * @param taskId number
   * @param minutes number
   */
  updateTaskTimeSpent: (taskId: string, minutes: number) => void;
  /**
   * Get the total amount of minutes spent
   * on tasks for the provided date.
   */
  getTimeSpentForDate: (date: Date) => number;
  /**
   * Holds the set global search term
   * that we use to search through the entire
   * application.
   */
  globalSearchTerm: string;
  setGlobalSearchTerm: any;
  modalState: IModalState;
  setModalState: any;
  toast: IToast;
  setToast: any;
  hideAmounts: boolean;
  setHideAmounts: any;
  filterTasksByType: string;
  setFilterTasksByType: any;
  buyerTasks: IEntry[];
  sellerTasks: IEntry[];
}

const initialState: IAppContext = {
  currentDate: new Date(),
  setCurrentDate: null,
  getTasksForCurrentDate: () => [],
  tasksForSelectedDate: [],
  allTasks: [],
  buyerTasks: [],
  sellerTasks: [],
  tasksBySelectedContract: [],
  markTask: () => null,
  addTask: () => null,
  editTask: () => null,
  deleteTask: () => null,
  updateTaskTimeSpent: () => null,
  getTimeSpentForDate: () => 0,
  globalSearchTerm: "",
  setGlobalSearchTerm: null,
  modalState: null,
  setModalState: null,
  toast: null,
  setToast: null,
  hideAmounts: false,
  setHideAmounts: null,
  filterTasksByType: null,
  setFilterTasksByType: null,
};

export interface IModalStateAction {
  label: string;
  callback: any;
  color: string;
  props: any[];
}

/**
 * Indicates the state of the modal.
 * It contains the modal state: shown, hidden
 * and also the content of the modal.
 */
export interface IModalState {
  title: string;
  props: any;
  content: ReactNode;
  actions?: IModalStateAction[];
  size?: string;
}

export const useAppContext = () => useContext(AppContext);
export const AppContext = createContext(initialState);

const AppProvider = (props: { children: any }) => {
  const typeHelper = new TypeHelper();
  const tasksDb = new TasksDB();
  // Indicates the currently selected date.
  const [currentDate, setCurrentDate] = useState<Date>(null);
  // Holds the list of all the tasks.
  const [allTasks, setAllTasks] = useState<IEntry[]>([]);
  const [buyerTasks, setBuyerTasks] = useState<IEntry[]>([]);
  const [sellerTasks, setSellerTasks] = useState<IEntry[]>([]);
  // Holds the tasks list filtered by the current quantifier id.
  const [tasksBySelectedContract, setTasksBySelectedContract] = useState<
    IEntry[]
  >([]);
  // Holds the list of filtered tasks by current date.
  const [tasksForSelectedDate, setTasksForSelectedDate] = useState<IEntry[]>(
    []
  );
  // Holds the global search term.
  const [globalSearchTerm, setGlobalSearchTerm] = useState<string>("");
  // Indicates if the modal is shown or not.
  const [modalState, setModalState] = useState<IModalState>(null);
  // Indicates if the amounts (prices) should be hidden on the interface.
  const [hideAmounts, setHideAmounts] = useState<boolean>(false);
  // Indicates the currently selected task filter by type.
  const [filterTasksByType, setFilterTasksByType] = useState<string>();
  // Tasks related helper class.
  const tasksHelper = new TasksHelper();
  // Tasks related db methods.
  const tasksStore = new TasksStore();
  // Get the current user so we can use the uid.
  const { currentUser } = useAuth();
  // Get the currently selected quantifier.
  const { currentContract, buyerContracts, sellerContracts } = useContracts();
  /**
   * Holds the current toast content.
   * If the content is null then the toast
   * is hidden.
   */
  const [toast, setToast] = useState<IToast>(null);
  /**
   * Holds the list of all subscriptions from
   * which we need to unsubscribe when this
   * provider unloads.
   */
  const [subscriptions, setSubscriptions] = useState<Unsubscribe[]>([]);

  /**
   * Read and set the current date.
   */
  useEffect(() => {
    /**
     * Get todays date without the time.
     */
    const dateTimeNow = new Date().toISOString();
    const index = dateTimeNow.indexOf("T");
    const dateOnlyNow = dateTimeNow.substring(0, index);
    /**
     * Set the current date.
     */
    setCurrentDate(new Date(dateOnlyNow));
  }, []);
  /**
   * Get the list of all the tasks from
   * the database.
   */
  useEffect(() => {
    /**
     * Setup the firestore snapshot function to
     * always refresh the data when it changes.
     */
    if (!currentUser) {
      return;
    }
    // const collection = tasksStore.getNotesQueryByDate(currentUser.uid);
    /**
     * TODO: need to change the way the list of all tasks is loaded.
     * 1. We need to load the tasks for the contracts that belong to us.
     * 2. Separate them by buyer tasks and seller tasks.
     *
     * Steps:
     * 1. Get the list of contracts that belong to the current user.
     * 2. Parse through each of the contracts and collect all the tasks for them.
     * 3. Store the tasks in 2 lists: buyerTasks and sellerTasks.
     */
    // 1. Collect the tasks for the buyerContracts.
    // const queryTasksBySeller = tasksDb.getNotesQueryByContractsForPeriod(sellerContractIds, startOfMonth(new Date()).getTime(), endOfMonth(new Date()).getTime());

    // const buyerContractIds = buyerContracts.map((c: IContract) => c.id);
    if (!!sellerContracts.length) {
      const sellerContractIds = sellerContracts.map((c: IContract) => c.id);
      if (!!sellerContractIds.length) {
        const queryTasksByBuyer = tasksDb.getNotesQueryByContractsForPeriod(
          sellerContractIds,
          startOfMonth(new Date("01/01/2022")).getTime(),
          endOfMonth(new Date()).getTime()
        );
        const unsubscribeFromSellerTasks = onSnapshot(
          queryTasksByBuyer,
          (snapshot: QuerySnapshot<DocumentData>) => {
            /**
             * Build the list of all tasks for the current
             * user for the entire current month.
             */
            const foundTasks = snapshot.docs.map((task: any) => ({
              ...task.data(),
              // Compute the time spent according to the todos or the task (if there are no todos yet).
              timeSpent:
                task.data().todos &&
                task.data().todos.length > 0 &&
                !!task.data().todos.find((todo: IEntryTodo) => !!todo.timeSpent)
                  ? task
                      .data()
                      .todos.reduce(
                        (acc: number, todo: IEntryTodo) =>
                          acc + (todo.timeSpent || 0),
                        0
                      )
                  : task.data().timeSpent || 0,
              id: task.id,
            }));
            setSellerTasks(foundTasks);
            /**
             * Build the list of tasks for the current
             * user, current month and the current quantifier.
             */
            const tasksByContract = foundTasks.filter(
              (task: IEntry) => task.quantifierId === currentContract.id
            );
            setTasksBySelectedContract(tasksByContract);
          }
        );
        setSubscriptions([...subscriptions, unsubscribeFromSellerTasks]);
      }
    }

    if (!!buyerContracts.length) {
      const buyerContractIds = buyerContracts.map((c: IContract) => c.id);
      if (!!buyerContractIds.length) {
        const queryTasksByBuyer = tasksDb.getNotesQueryByContractsForPeriod(
          buyerContractIds,
          startOfMonth(new Date("01/01/2022")).getTime(),
          endOfMonth(new Date()).getTime()
        );
        const unsubscribeFromBuyerTasks = onSnapshot(
          queryTasksByBuyer,
          (snapshot: QuerySnapshot<DocumentData>) => {
            /**
             * Build the list of all tasks for the current
             * user for the entire current month.
             */
            const foundTasks = snapshot.docs.map((task: any) => ({
              ...task.data(),
              // Compute the time spent according to the todos or the task (if there are no todos yet).
              timeSpent:
                task.data().todos &&
                task.data().todos.length > 0 &&
                !!task.data().todos.find((todo: IEntryTodo) => !!todo.timeSpent)
                  ? task
                      .data()
                      .todos.reduce(
                        (acc: number, todo: IEntryTodo) =>
                          acc + (todo.timeSpent || 0),
                        0
                      )
                  : task.data().timeSpent || 0,
              id: task.id,
            }));
            setBuyerTasks(foundTasks);
            /**
             * Build the list of tasks for the current
             * user, current month and the current quantifier.
             */
            // const tasksByContract = allTasks.filter((task: IEntry) => task.quantifierId === currentContract.id);
            // setTasksBySelectedContract(tasksByContract);
          }
        );
        setSubscriptions([...subscriptions, unsubscribeFromBuyerTasks]);
      }
    }

    /**
     * Get the hideAmounts preference.
     */
    setHideAmounts(
      preferenceService.getPreference(currentUser.uid, "_HIDE_AMOUNTS")
    );

    // setSubscriptions([unsubscribeFromSellerListener, unsubscribeFromBuyerListener]);

    return () => {
      subscriptions.forEach((unsub) => unsub());
    };
  }, [
    currentUser,
    buyerContracts,
    sellerContracts,
    currentDate,
    currentContract,
  ]);

  /**
   * Update the list of tasks for
   * the current date when the selected
   * date has changed or when the
   * list of tasks changed.
   */
  useEffect(() => {
    if (!currentDate || !currentContract) {
      return;
    }
    updateTasksForCurrentDate(
      currentDate,
      currentContract.id,
      filterTasksByType || null
    );
  }, [currentDate, allTasks, filterTasksByType]);

  /**
   * Monitor the changes to the hideAmounts property
   * and save the last change to the localStorage.
   */
  useEffect(() => {
    if (!currentUser) return;
    preferenceService.savePreference(
      currentUser.uid,
      "_HIDE_AMOUNTS",
      hideAmounts || false
    );
  }, [hideAmounts]);

  /**
   * Monitor changes to the list of all
   * tasks so we always have the invoicing
   * list up to date.
   */
  useEffect(() => {
    setAllTasks([...sellerTasks, ...buyerTasks]);
  }, [sellerTasks, buyerTasks]);
  /**
   * Update the list of tasks for the currently
   * selected date.
   *
   * This method should be called when a new
   * task is being added or removed.
   *
   * @param day string
   * @returns IEntry[]
   */
  const updateTasksForCurrentDate = (
    day: Date,
    contractId: string,
    filterByType?: string
  ): void => {
    let byDate = tasksHelper.filterTasksForProvidedDayByContract(
      sellerTasks,
      day,
      contractId
    );
    if (filterByType) {
      byDate = byDate.filter(
        (task: IEntry) =>
          (task.type || typeHelper.detectTaskTypeByLabel(task.label)) ===
          filterByType
      );
    }
    setTasksForSelectedDate(byDate);
  };
  /**
   * Get the list of tasks for the current
   * date.
   *
   * @returns IEntry[]
   */
  const getTasksForCurrentDate = (): IEntry[] => {
    return tasksForSelectedDate;
  };
  /**
   * Change the completion status of the
   * task with the provided id.
   *
   * @param taskId number
   * @returns void
   */
  const markTask = (taskId: string, isChecked: boolean): void => {
    // Find the task we need to update.
    const foundTask = allTasks.find((t) => t.id === taskId);
    // Update the completed status.
    if (foundTask) {
      foundTask.completed = !foundTask.completed;
      // Update the state in the db.
      tasksStore.updateCompletedInDb(taskId, isChecked).then();
    }
  };
  /**
   * Update the time spent on the provided
   * task.
   *
   * @param taskId number
   * @param minutes number
   */
  const updateTaskTimeSpent = (taskId: string, minutes: number): void => {
    // Add the new task to the remote db.
    tasksStore.setTimeSpent(taskId, minutes);
  };
  /**
   * Get the number of total minutes spent on the
   * tasks for the provided date.
   *
   * @param date
   * @returns number
   */
  const getTimeSpentForDate = (date: Date): number => {
    // Get the tasks for the specified date.
    const tasks = tasksHelper.filterTasksForProvidedDayByContract(
      allTasks,
      date,
      currentContract.uid
    );
    // Calculate the total number of minutes from each task.
    return tasks.reduce(
      (accumulator: number, currTask: IEntry) =>
        accumulator + (currTask.timeSpent || 0),
      0
    );
  };
  /**
   * Add the newly created task to the
   * list of tasks.
   */
  const addTask = (entry: IEntry): void => {
    // Add the new task to the remote db.
    tasksStore.addNote(entry).then(() => {
      // Refresh the list of tasks from remote.
      // refreshListOfTasks();
    });
  };
  /**
   * Change the list of tasks to reflect the
   * changed to the provided task.
   *
   * @param entry IEntry
   * @returns void
   */
  const editTask = (entry: IEntry): void => {
    tasksStore.updateNote(entry).then(() => {
      // Refresh the list of tasks from remote.
      // refreshListOfTasks();
    });
  };
  /**
   * Delete a task from the list of tasks.
   */
  const deleteTask = (taskId: string): void => {
    tasksStore.deleteInDb(taskId);
  };

  return (
    <AppContext.Provider
      value={{
        currentDate,
        setCurrentDate,
        getTasksForCurrentDate,
        tasksForSelectedDate,
        allTasks,
        tasksBySelectedContract,
        markTask,
        addTask,
        editTask,
        deleteTask,
        updateTaskTimeSpent,
        getTimeSpentForDate,
        globalSearchTerm,
        setGlobalSearchTerm,
        modalState,
        setModalState,
        toast,
        setToast,
        hideAmounts,
        setHideAmounts,
        filterTasksByType,
        setFilterTasksByType,
        buyerTasks,
        sellerTasks,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppProvider;
