import { createContext, useEffect, useState } from "react";
import { useContext } from "react";
import { signInWithEmailAndPassword, signOut, createUserWithEmailAndPassword, onAuthStateChanged, User, updateProfile, updateEmail, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from "./firebase";
import { IUserProfile, usersService } from "./Users/UsersService";

export interface IUserDisplayname {
  userId: string;
  displayName: string;
}

export const useAuth = () => useContext(AuthContext);

export const AuthContext = createContext(null);

const AuthProvider = (props: { children: any }) => {
  // Holds the currently logged in user object.
  const [currentUser, setCurrentUser] = useState<User>();
  // Holds the personal user information for the logged in user.
  const [personalData, setPersonalData] = useState<IUserProfile>(null);
  // Holds the role of the currently logged in user.
  const [roles, setRoles] = useState<string[]>([]);
  // Holds the profile image for the user.
  const [profileImageUrl, setProfileImageUrl] = useState<string>('');
  // Holds a list of all users and their business names.
  const [usersDisplaynames, setUsersDisplaynames] = useState<IUserDisplayname[]>([]);
  
  /**
   * Monitor if the auth state changes and
   * set the current user if it's
   */
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user: User) => {
      // 
      // Set the user object from Firebase.
      setCurrentUser(user);
      // Set the user profile object from the users table.
      if (user?.uid) {
        usersService.fetchByUserId(user.uid)
          .then((userData: IUserProfile) => {
            setPersonalData(userData);
            // Set the role so we don't need the personalData object.
            setRoles(userData.role?.split(' '));
          });
      } else setPersonalData({
        displayName: '',
        firstName: '',
        lastName: '',
        email: '',
        businessName: '',
        businessLocation: '',
        quantifier: null,
        role: '',
        userId: '',
        jobName: '',
        bio: '',
      });
    });

    /**
     * Get a list of all user display names so we have it
     * cached and hence not required to read them
     * again.
     * Note: used by the INVOICES.
     */
    usersService.getListOfUserDisplayNames()
      .then((allUsers: IUserDisplayname[]) => setUsersDisplaynames(allUsers));

    return unsubscribe;
  }, []);

  /**
   * Get the profile picture for the current user.
   */
  useEffect(() => {
    // Get the user avatar from the storage bucket.
    if (!currentUser) return;
    usersService.getAvatarInStorage(currentUser.uid).then((url: string) => {
      setProfileImageUrl(url);
    }).catch((error: any) => console.error(error));
  }, [currentUser]);

  /**
   * Sign up the user with the provided email and
   * password
   * @param email string
   * @param password string
   * @returns Promise<UserCredentials>
   */
  const signUp = (email: string, password: string, displayName: string) => {
    return createUserWithEmailAndPassword(auth, email, password)
      .then(() => {
        updateProfile(auth.currentUser, { displayName }).then();
      })
      .catch((error: any) => console.log(error));
  }

  /**
   * Update the user information.
   *
   * @param user IUserProfile
   * @param photo 
   */
  const updateUser = (user: IUserProfile) => {
    // Remove the uid from the object.
    const { uid, ...userForUpdate } = user;
    if (user.uid) {
      return usersService.updateInDb(user.uid, userForUpdate)
        // Refresh personalData locally.
        .then(() => setPersonalData(user));
    } else {
      return usersService.addInDb(userForUpdate)
        // Refresh personalData locally.
        .then(() => setPersonalData(user));
    }
  }

  /**
   * Update the email address used by the current user.
   * Note: NO checks are being performed right now.
   *
   * @param newEmail string
   * @returns void
   */
  const updateProfileEmail = (newEmail: string) => {
    return updateEmail(currentUser, newEmail)
      .then(() => usersService.updateInDb(personalData.uid, { ...personalData, email: newEmail }))
      .catch((error: any) => console.error(error));
  }

  /**
   * Send a password reset link to the user email
   * address.
   * 
   * @param email string
   * @returns void
   */
  const resetPasswordByEmail = (email: string) => {
    return sendPasswordResetEmail(auth, email);
  }

  /**
   * Update the profile picture for the currently
   * logged in user.
   *
   * @param file File
   * @returns Promise<any>
   */
  const updateProfileImage = (file: File) => {
    return usersService.updateAvatarInDb(currentUser.uid, file)
      .then(() =>
        usersService.getAvatarInStorage(currentUser.uid).then((url: string) => {
          setProfileImageUrl(url);
        }).catch((error: any) => console.error(error)));
  }

  /**
   * Get the avatar for the current user.
   * 
   * @returns string
   */
  const getProfileImage = async () => {
    return await usersService.getAvatarInStorage(currentUser.uid);
  }

  const login = (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password);
  }

  /**
   * Sign out the current user and
   * redirect to login page.
   */
  const logout = () => {
    signOut(auth).then();
  }

  const value: {
    currentUser: User,
    personalData: IUserProfile,
    profileImageUrl: string,
    signUp: any,
    login: any,
    logout: any,
    updateProfileEmail: any,
    resetPasswordByEmail: any,
    updateProfileImage: any,
    getProfileImage: any,
    updateUser: any,
    roles: string[],
    usersDisplaynames: IUserDisplayname[];
  } = {
    currentUser,
    personalData,
    profileImageUrl,
    updateProfileEmail,
    resetPasswordByEmail,
    updateProfileImage,
    getProfileImage,
    updateUser,
    signUp,
    login,
    logout,
    roles,
    usersDisplaynames,
  }

  return (
    <AuthContext.Provider value={value}>
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthProvider