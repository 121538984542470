import { IContract } from "state/Contracts/ContractsService";
import { IUserDisplayname } from "state/useAuthState";

export enum ContractInvoiceStatusEnum {
  IN_PROGRESS,
  FINISHED,
  CLOSED,
  ATTENTION,
}

export interface IContractInvoice {
  id: string;
  /**
   * Indicates the contract which the invoice
   * represents.
   */
  contractId: string;
  /**
   * Holds the name of the entity that
   * the invoice is addressed to.
   */
  for: IUserDisplayname;
  /**
   * Timestamp of the target date when the
   * invoice should be generated.
   */
  invoiceDate: number;
  /**
   * Indicates if the invoice has been
   * accepted or not by the client.
   */
  accepted: boolean;
  /**
   * Indicates the general status of the
   * invoice.
   */
  status: ContractInvoiceStatusEnum;
  /**
   * Indicates the number of minutes spent
   * on the tasks corresponding to the current
   * invoice.
   */
  timeSpent: number;
  /**
   * Holds the rate for the current
   * contract.
   */
  rate: number;
  /**
   * Indicates the total amount due for
   * the total time spent corresponding
   * to the current invoice.
   */
  amount?: number;
  /**
   * Indicates if the current invoice has
   * been saved into the database already.
   */
  savedDate?: number;
}

export interface IContractInvoicesContainer {
  id?: string;
  contractId: string;
  invoices: IContractInvoice[];
}