// Chakra imports
import {
  Flex,
  Image,
  Icon,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

// Custom components
import Card from "components/card/Card";

// Assets
import balanceImg from "assets/img/dashboards/balanceImg.png";
import fakeGraph from "assets/img/dashboards/fakeGraph.png";
import { MdArrowCircleDown, MdArrowCircleUp, MdDomain } from "react-icons/md";
// Assets
import { useContracts } from "state/useContractsState";
import { useAppContext } from "state/useAppState";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IContract } from "state/Contracts/ContractsService";
import { TasksHelper } from "logic/tasks.helper";
import { useAuth } from "state/useAuthState";
import InvoicedContractItem from "./InvoicedContractItem";
import ContractHelper from "logic/contract.helper";

export default function InvoicedContracts(props: { [x: string]: any }) {
  const { ...rest } = props;
  // Ellipsis modals
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();

  // Chakra Color Mode
  const greenIcon = useColorModeValue("green.500", "green.300");
  const redIcon = useColorModeValue("red.500", "red.300");
  const balanceBg = useColorModeValue("blue.900", "#1B254B");
  const textColor = useColorModeValue("secondaryGray.500", "white");
  const textHover = useColorModeValue(
    { color: "secondaryGray.900", bg: "unset" },
    { color: "secondaryGray.500", bg: "unset" }
  );
  const bgList = useColorModeValue("white", "whiteAlpha.100");
  const bgShadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  const headerBoxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const noContractsBoxBg = useColorModeValue("red.50", "red.50");

  const { buyerContracts, sellerContracts } = useContracts();
  const { allTasks, hideAmounts } = useAppContext();
  const { personalData } = useAuth();
  const tasksHelper = new TasksHelper();
  const contractHelper = new ContractHelper();
  // Holds the list of processed contracts.
  const [contracts, setContracts] = useState<IContract[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [totalTime, setTotalTime] = useState<number>(0);

  /**
   * Process the list of contracts by computing the
   * total time spent and the price.
   */
  const processContracts = useCallback(() => {
    // Cumulate all contract types into one array.
    const allContracts = [...sellerContracts, ...buyerContracts];
    // Parse through all the contracts.
    return allContracts.map((contract: IContract) => {
      const invoiceType = contractHelper.getContractType(
        contract,
        personalData.userId
      );
      const totalTime = tasksHelper.filterTimeSpentForContractForPeriod(
        allTasks,
        new Date(),
        contract.id
      );
      return {
        ...contract,
        timeSpent: totalTime,
        price:
          (totalTime / 60) * contract.rate * (invoiceType === "buyer" ? -1 : 1),
        invoiceType,
      };
    });
  }, [sellerContracts, buyerContracts, allTasks]);

  /* A function that takes in an array of contracts and returns the total time. */
  const getTotalTime = useCallback(
    (processedContracts: IContract[]) => {
      return processedContracts.reduce(
        (acc: number, curr: IContract) => acc + curr.timeSpent,
        0
      );
    },
    [sellerContracts, buyerContracts, allTasks]
  );

  /* A function that takes in an array of contracts and returns the total price. */
  const getTotalPrice = useCallback(
    (processedContracts: IContract[]) => {
      return processedContracts.reduce(
        (acc: number, curr: IContract) => acc + curr.price,
        0
      );
    },
    [sellerContracts, buyerContracts, allTasks]
  );

  useEffect(() => {
    if (!sellerContracts?.length && !buyerContracts?.length) return;
    const theProcessedContracts = processContracts();
    // Set the processed contracts state.
    setContracts(theProcessedContracts);
    // Set the total time state.
    setTotalTime(getTotalTime(theProcessedContracts));
    // Set the total price state.
    setTotalPrice(getTotalPrice(theProcessedContracts));
  }, [sellerContracts, buyerContracts, allTasks]);

  return (
    <Card flexDirection="column" w="100%" {...rest}>
      <Flex
        justify="space-between"
        p="20px"
        mb="20px"
        borderRadius="16px"
        bgColor={balanceBg}
        bgImage={balanceImg}
        bgPosition="right"
        bgSize="cover"
      >
        <Flex align="center" justify="space-between" w="100%">
          <Flex flexDirection="column" me="20px">
            <Text color="white" fontSize="sm" fontWeight="500">
              Invoiced Contracts
            </Text>
            <Text
              color="white"
              fontSize="34px"
              fontWeight="700"
              lineHeight="100%"
            >
              {totalPrice.toLocaleString("de-DE", {
                style: "currency",
                currency: "EUR",
              })}
            </Text>
          </Flex>
          <Flex
            flexDirection="column"
            ms="auto"
            justify="space-between"
            align="flex-end"
          >
            <Image src={fakeGraph} w="59px" h="17px" />
          </Flex>
        </Flex>
      </Flex>
      {/* The explanations. */}
      <Flex
        direction="column"
        bg={headerBoxBg}
        p="16px 20px"
        borderRadius="14px"
        mb="20px"
      >
        <Text fontSize="sm" fontWeight="700" color={textColor}>
          The list of all your contracts
        </Text>
        <Text fontSize="sm" fontWeight="500" color="secondaryGray.600">
          Please select a contract to view a list of all the invoices for it.
          Once an invoice got the status Accepted, any time spent on tasks
          belonging to it cannot be changed.
        </Text>
      </Flex>
      <Text color="secondaryGray.600" fontWeight="500" fontSize="sm" mb="10px">
        All contracts
      </Text>
      {!!contracts.length && (
        <Flex direction="column">
          {contracts.map((contract: IContract) => (
            <InvoicedContractItem
              key={contract.id}
              mb="20px"
              contract={contract}
              type={contract.invoiceType}
              icon={
                <Icon
                  as={
                    contract.invoiceType === "seller"
                      ? MdArrowCircleDown
                      : MdArrowCircleUp
                  }
                  color={
                    contract.invoiceType === "seller" ? greenIcon : redIcon
                  }
                  w="20px"
                  h="18px"
                />
              }
            />
          ))}
        </Flex>
      )}
      {!contracts.length && (
        <Flex
          direction="column"
          bg={noContractsBoxBg}
          p="16px 20px"
          borderRadius="14px"
          mb="20px"
        >
          <Text fontSize="sm" fontWeight="500" color="secondaryGray.600">
            There are no contracts to be displayed yet.
            <br />
            These contracts must be initialised by a user that wishes to pay for
            your services.
          </Text>
        </Flex>
      )}
    </Card>
  );
}
