/* eslint-disable */

import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import CustomButton from "components/CustomButton/CustomButton";
import html2pdf from "html2pdf.js";
import { TasksHelper } from "logic/tasks.helper";
import { TimeHelper } from "logic/Time.helper";
import { useRef, useState } from "react";
import { MdSave } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { IContractInvoice } from "state/Invoices/Dto";
import { IEntry } from "state/Tasks/TasksDTO";

import { IInvoicePeriod } from "../../invoice-tasks";
import { DateChecker } from "./DateChecker";
import InvoiceTasksListHead from "./InvoiceTasksListHead";
import InvoiceTasksListRow from "./InvoiceTasksListRow";

const generatePDF = (period: IInvoicePeriod) => {
  const element = document.getElementById("invoice_tasks_table");
  setTimeout(() => {
    const options = {
      filename: `tasks_${period.year}_${period.month}.pdf`,
      image: { type: "jpeg", quality: 0.95 },
      html2canvas: { scale: 2 },
      pagebreak: "avoid-all",
      jsPDF: { unit: "cm", format: "a4", orientation: "portrait" },
    };

    html2pdf().set(options).from(element).save();
  }, 1000);
};

export default function InvoiceTasksList(props: {
  contractId: string;
  period: IInvoicePeriod;
  tasks: IEntry[];
  invoice: IContractInvoice;
}) {
  // const [ sorting, setSorting ] = React.useState<SortingState>([]);
  const textColor = useColorModeValue("navy.700", "white");
  // const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  const tasksHelper = new TasksHelper();
  const timeHelper = new TimeHelper();
  const dateChecker = new DateChecker();
  const buttonColor = useColorModeValue("blue.700", "white");
  console.log("contractId", props.contractId);
  console.log("period", props.period);
  console.log("tasks", props.tasks);

  return (
    <Card id="invoiceTasksList">
      <Flex direction="column" overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex
          align={{ lg: "center" }}
          justify={{ base: "space-between" }}
          w="100%"
          px="20px"
          mb="20px"
        >
          {/* The title. */}
          <Text
            color={textColor}
            fontSize="xl"
            fontWeight="600"
            lineHeight="100%"
          >
            List of tasks for invoice {props.invoice?.invoiceDate}
          </Text>

          <Flex
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <Flex direction="row">
              {/* The button to generate the downloadable pdf object. */}
              <CustomButton
                icon={MdSave}
                label="Download PDF"
                callback={() => generatePDF(props.period)}
                color={buttonColor}
              />
            </Flex>
          </Flex>
        </Flex>
        <Box>
          <Table
            id="invoice_tasks_table"
            variant="simple"
            color="gray.500"
            mt="12px"
          >
            <Thead>
              <Tr>
                <InvoiceTasksListHead label="Type" />
                <InvoiceTasksListHead label="Date" />
                <InvoiceTasksListHead label="Description" />
                <InvoiceTasksListHead label="Time spent" />
              </Tr>
            </Thead>
            <Tbody>
              {props.tasks.map((task: IEntry) => (
                <InvoiceTasksListRow
                  key={task.id}
                  task={task}
                  alternator={dateChecker.hasDateChanged(
                    new Date(new Date(task.created).toDateString())
                  )}
                />
              ))}
            </Tbody>
            <Tfoot>
              <Tr>
                <Td></Td>
                <Td>TOTAL</Td>
                <Td display="flex" gap={2} flexDirection="row">
                  <Text fontWeight={700}>
                    {timeHelper.humanize(
                      tasksHelper.getTotalMinutesSpent(props.tasks)
                    )}
                  </Text>
                  logged
                </Td>
                <Td></Td>
              </Tr>
            </Tfoot>
          </Table>
        </Box>
      </Flex>
    </Card>
  );
}
