// Chakra imports
import banner from "assets/img/auth/banner.png";
// import profile from 'assets/img/crm/vbz.png';
import logoWhite from "assets/img/layout/logoWhite.png";
import { useEffect, useState } from "react";
import { IEntry } from "state/Tasks/TasksDTO";
import { useAppContext } from "state/useAppState";

import { Box, Flex, SimpleGrid } from "@chakra-ui/react";

import BusinessInfoSettings from "./components/BusinessInfoSettings";
import ChangeEmail from "./components/ChangeEmail";
import ChangePassword from "./components/ChangePassword";
import PersonalInfoSettings from "./components/PersonalInfoSettings";
import Profile from "./components/Profile";

export default function ProfileSettingsView() {
  // Get the list of tasks for the selected date.
  const { tasksForSelectedDate } = useAppContext();
  const [tasks, setTasks] = useState<IEntry[]>([]);

  useEffect(() => {
    if (!tasksForSelectedDate) return;
    setTasks(tasksForSelectedDate);
  }, [tasksForSelectedDate]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 2 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        {/* Column Left */}
        <Flex direction="column">
          <Profile name="Vlad Mihalache" avatar={logoWhite} banner={banner} />
          <PersonalInfoSettings />
        </Flex>
        {/* Column Right */}
        <Flex direction="column">
          <BusinessInfoSettings />
          <ChangePassword />
          <ChangeEmail />
          {/* <ActionHistory /> */}
        </Flex>
      </SimpleGrid>
    </Box>
  );
}
