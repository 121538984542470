// Chakra imports
import { Button, Flex, FormControl, SimpleGrid, Skeleton, Stack, Text, useColorModeValue, useToast } from '@chakra-ui/react';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { useState } from 'react';
import { useAuth } from 'state/useAuthState';
import { IUserProfile } from 'state/Users/UsersService';

export default function BusinessInfoSettings() {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';
  const toast = useToast();
  const { updateUser, personalData } = useAuth();
  // Holds the field values.
  const [fields, setFields] = useState<IUserProfile>({
    uid: personalData?.uid || '',
    displayName: personalData?.displayName || '',
    firstName: personalData?.firstName || '',
    lastName: personalData?.lastName || '',
    email: personalData?.email || '',
    businessName: personalData?.businessName || '',
    businessLocation: personalData?.businessLocation || '',
    quantifier: null,
    userId: personalData?.userId || '',
    jobName: personalData?.jobName || '',
    bio: personalData?.bio || '',
    role: personalData?.role || '',
  });
  // Update other personal information.
  const updatePersonalData = () => {
    // Collect the data into the required object format (IUserProfile).
    console.log('updating personal data:', fields);
    updateUser(fields)
      .then(() => toast({
        title: 'Info updated!',
        description: "Business information changes were applied.",
        status: 'success',
        duration: 9000,
        isClosable: true,
      }))
      .catch(() => toast({
        title: 'Update failed!',
        description: "Business information changes were not applied.",
        status: 'error',
        duration: 9000,
        isClosable: true,
      }));
  }

  if (!personalData) {
    return (
      <Stack>
        <Skeleton height='20px' />
        <Skeleton height='20px' />
        <Skeleton height='20px' />
      </Stack>
    )
  }

  return (
    <Card mb='20px'>
      <Flex direction='column' mb='40px' ms='10px'>
        <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
          Business Information
        </Text>
        <Text fontSize='md' color={textColorSecondary}>
          Here you can change you business account information
        </Text>
      </Flex>
      <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={{ base: '20px', xl: '20px' }}>

        {/* Business name field. */}
        <InputField type='string' mb='25px' id='businessName' label='Business name' placeholder='CompanyName Inc.' value={fields.businessName} onChange={(e: any) => setFields({ ...fields, businessName: e.target.value })} />
        {/* Business location field. */}
        <InputField type='string' mb='25px' id='email' label='Business address' placeholder='15 Heroes Avenue, 46573, Dallas' value={fields.businessLocation} onChange={(e: any) => setFields({ ...fields, businessLocation: e.target.value })} />

      </SimpleGrid>

      <Button variant='brand' minW='183px' fontSize='sm' fontWeight='500' ms='auto' onClick={() => updatePersonalData()}>
        Save changes
      </Button>
    </Card>
  );
}
