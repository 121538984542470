import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';
import AuthProvider from 'state/useAuthState';
import IsLoggedIn from 'components/IsLoggedIn';
import DeveloperDashboardLayout from './layouts/admin';
import AuthLayout from 'layouts/auth';
import IsLoggedOut from 'components/IsLoggedOut';
import SignIn from 'views/auth/signIn';
import DeveloperDashboard from 'views/developer-dashboard';
import ContractProvider from 'state/useContractsState';
import AppProvider from 'state/useAppState';
import DayView from 'views/day';
import AdministratorDashboard from 'views/administrator';
import ProfileSettingsView from 'views/profile';
import InvoicesView from 'views/invoices';
import ContractInvoicesView from 'views/invoices/invoice/ContractInvoicesView';
import ProjectsView from 'views/projects';
import ProjectsProvider from 'state/Projects/useProjectsState';
import TicketsProvider from 'state/Tickets/useTicketsState';
import ProjectView from 'views/projects/project';
import InvoiceTasks from 'views/invoices/invoice/invoice-tasks';
import { RenderedTasksListForPdf } from 'views/invoices/invoice/components/ContractInvoiceTasksList/PdfRenderer/RenderedTasksListForPdf';

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <AuthProvider>
        <ContractProvider>
          <AppProvider>
            <ProjectsProvider>
              <TicketsProvider>
                <BrowserRouter>
                  <Routes>
                    <Route path={`/administrator`} element={
                      <IsLoggedIn>
                        <DeveloperDashboardLayout>
                          <AdministratorDashboard />
                        </DeveloperDashboardLayout>
                      </IsLoggedIn>
                    } />
                    <Route path={`/dashboard`} element={
                      <IsLoggedIn>
                        <DeveloperDashboardLayout>
                          <DeveloperDashboard />
                        </DeveloperDashboardLayout>
                      </IsLoggedIn>
                    } />
                    <Route path={`/projects`} element={
                      <IsLoggedIn>
                        <DeveloperDashboardLayout>
                          <ProjectsView />
                        </DeveloperDashboardLayout>
                      </IsLoggedIn>
                    } />
                    <Route path={`/project/:projectId`} element={
                      <IsLoggedIn>
                        <DeveloperDashboardLayout>
                          <ProjectView />
                        </DeveloperDashboardLayout>
                      </IsLoggedIn>
                    } />
                    <Route path={`/day`} element={
                      <IsLoggedIn>
                        <DeveloperDashboardLayout>
                          <DayView />
                        </DeveloperDashboardLayout>
                      </IsLoggedIn>
                    } />
                    <Route path={`/profile`} element={
                      <IsLoggedIn>
                        <DeveloperDashboardLayout>
                          <ProfileSettingsView />
                        </DeveloperDashboardLayout>
                      </IsLoggedIn>
                    } />
                    <Route path={`/invoices/:contractId/:year/:month/pdf`} element={
                      <IsLoggedIn>
                        <RenderedTasksListForPdf />
                      </IsLoggedIn>
                    } />
                    <Route path={`/invoices/:contractId/:year/:month`} element={
                      <IsLoggedIn>
                        <DeveloperDashboardLayout>
                          <InvoiceTasks />
                        </DeveloperDashboardLayout>
                      </IsLoggedIn>
                    } />
                    <Route path={`/invoices/:contractId`} element={
                      <IsLoggedIn>
                        <DeveloperDashboardLayout>
                          <ContractInvoicesView />
                        </DeveloperDashboardLayout>
                      </IsLoggedIn>
                    } />
                    <Route path={`/invoices`} element={
                      <IsLoggedIn>
                        <DeveloperDashboardLayout>
                          <InvoicesView />
                        </DeveloperDashboardLayout>
                      </IsLoggedIn>
                    } />
                    <Route path={`/login`} element={
                      <IsLoggedOut>
                        <AuthLayout>
                          <SignIn />
                        </AuthLayout>
                      </IsLoggedOut>
                    } />
                    <Route path="*" element={<Navigate to="/dashboard" replace />} />
                  </Routes>
                </BrowserRouter>
              </TicketsProvider>
            </ProjectsProvider>
          </AppProvider>
        </ContractProvider>
      </AuthProvider>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById('root')
);
