import Card from "components/card/Card";
import { TasksHelper } from "logic/tasks.helper";
import { TimeHelper } from "logic/Time.helper";
import { useEffect, useState } from "react";
import Calendar, { CalendarTileProperties } from "react-calendar";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { IEntry } from "state/Tasks/TasksDTO";
import { useAppContext } from "state/useAppState";
import { useContracts } from "state/useContractsState";

import { Text, Icon } from "@chakra-ui/react";

import "react-calendar/dist/Calendar.css";
import "assets/css/MiniCalendar.css";

export default function MiniCalendar(props: {
  selectRange: boolean;
  [x: string]: any;
}) {
  const { selectRange, ...rest } = props;
  const [value, setValue] = useState(null);
  // The time helpers class.
  const timeHelper = new TimeHelper();
  // Helps with different tasks computations.
  const tasksHelper = new TasksHelper();
  // Get the current date setter from the app context.
  const { currentDate, setCurrentDate, tasksBySelectedContract } =
    useAppContext();
  const { currentContract } = useContracts();
  const navigate = useNavigate();
  /**
   * Refresh the currently shown list of
   * tasks.
   */
  const handleChangeDay = (date: Date) => {
    // var isoDateTime = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
    const selectedDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    var isoDateTime = new Date(
      selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000
    ).toISOString();
    // Set the current date to the date provided.
    setCurrentDate(new Date(isoDateTime));
    // Navigate to the day view.
    navigate("/day");
  };

  useEffect(() => {
    if (value) return;
    setValue(new Date());
  }, []);
  /**
   * Return the required color for each
   * date tile based on if the day is complete
   * or not.
   *
   * @param date
   * @returns
   */
  const getStylesForCell = (date: Date) => {
    if (!currentContract || !currentDate) {
      return;
    }
    let styles = "";
    // Check if the current date has any tasks associated with it.
    const tasksForToday = tasksBySelectedContract.filter(
      (task: IEntry) =>
        timeHelper.getOnlyDate(new Date(task.created)) ===
        timeHelper.getOnlyDate(date)
    );
    // Check if the current date has tasks of which the total timeSpent sums up to 8h.
    const totalTimeSpentForDate = tasksForToday
      ? tasksHelper.getTotalMinutesSpent(tasksForToday)
      : 0;
    // Get the number of total hours spent on current date.
    const isDateComplete =
      totalTimeSpentForDate / 60 >= currentContract.hoursPerDay;
    // If there are tasks for the current date then display something in the tile.
    if (tasksForToday.length && !isDateComplete) {
      styles += "react-calendar__month-view__days__incomplete";
      // #FEEBC8
    }
    // Mark as green if the current day is complete.
    if (tasksForToday.length && isDateComplete) {
      styles += "react-calendar__month-view__days__complete";
      // #C6F6D5
    }
    // Strike through if all tasks from day were checked.
    return styles;
  };

  return (
    <Card
      alignItems="center"
      flexDirection="column"
      w="100%"
      maxW="max-content"
      p="15px"
      h="max-content"
      {...rest}
    >
      <Calendar
        onChange={setValue}
        onClickDay={(event: any) => handleChangeDay(event)}
        tileClassName={(props: CalendarTileProperties) =>
          getStylesForCell(props.date)
        }
        value={value}
        selectRange={selectRange}
        view={"month"}
        tileContent={<Text color="brand.500" />}
        prevLabel={<Icon as={MdChevronLeft} w="24px" h="24px" mt="4px" />}
        nextLabel={<Icon as={MdChevronRight} w="24px" h="24px" mt="4px" />}
      />
    </Card>
  );
}
