// Chakra imports
import { Badge, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import CustomButton from 'components/CustomButton/CustomButton';

// Custom components
import IconBox from 'components/icons/IconBox';
import { formatDistance } from 'date-fns';
import { TimeHelper } from 'logic/Time.helper';
import { useEffect, useState } from 'react';
import { MdPageview } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { ContractsService, IContract } from 'state/Contracts/ContractsService';
import { usersService } from 'state/Users/UsersService';

export default function InvoicedContractItem(props: {
  contract: IContract;
  type: 'seller' | 'buyer';
  icon: any;
  [x: string]: any;
}) {
  const { contract, icon, type, ...rest } = props;

  const textColor = useColorModeValue('secondaryGray.700', 'white');
  const acceptButtonColor = useColorModeValue('green.500', 'green.200');
  const runningSinceColor = useColorModeValue('green.500', 'green.200');
  const loggedTimeColor = useColorModeValue('secondaryGray.900', 'secondaryGray.200');
  const notAcceptedYetColor = useColorModeValue('blue.500', 'blue.200');
  const notAcceptedColor = useColorModeValue('red.500', 'red.200');
  const expiresColor = useColorModeValue('red.500', 'red.200');
  const iconBoxBg = useColorModeValue('secondaryGray.300', 'navy.700');

  const timeHelper = new TimeHelper();
  const contractsService = new ContractsService();
  const navigate = useNavigate();

  const [displayName, setDisplayName] = useState<string>('');

  /**
   * Redirect the user to the indicated
   * location by the provided location.
   */
  const loadPage = (path: string) => {
    navigate(`${path}`);
  }

  useEffect(() => {
    // Get the name to be displayed for the current contract.
    usersService.getDisplayName(type === 'seller' ? contract.buyerId : contract.sellerId)
      .then((name: string) => setDisplayName(name));
  }, [contract]);

  return (
    <Flex justifyContent='center' alignItems='center' w='100%' {...rest}>
      {/* MdArrowCircleDown */}
      <IconBox h='42px' w='42px' bg={iconBoxBg} me='20px' icon={icon} />

      {/* Shows the contract details. */}
      <Flex direction='column' align='start' me='auto'>
        <Text color={textColor} fontSize='md' me='6px' fontWeight='700'>
          {!!displayName && displayName}

          {/* Indicates when contract will expire. */}
          {!!contract.endDate && !!contract.acceptedDate &&
            <Badge color={expiresColor}> {formatDistance(new Date(contract.endDate), new Date(), { addSuffix: true })}</Badge>
          }
        </Text>

        {/* Indicates the logged hours. */}
        {!!contract.acceptedDate &&
          <Flex direction='row'>
            <Text color={textColor} fontSize='sm' fontWeight='500' mr='5px'>
              Logged
            </Text>
            <Text color={loggedTimeColor} fontSize='sm' fontWeight='900'>
              {timeHelper.humanize(contract.timeSpent)}
            </Text>
          </Flex>
        }

        {/* Indicates since when the contract has been accepted. */}
        {!!contract.acceptedDate &&
          <Text color={runningSinceColor} fontSize='sm' fontWeight='500'>
            Running since {formatDistance(new Date(), new Date(contract.acceptedDate), { addSuffix: true })}
          </Text>
        }

        {/* Indicates if the contract has NOT been accepted YET. */}
        {!!!contract.acceptedDate && !!!contract.rejectedDate &&
          <Text color={notAcceptedYetColor} fontSize='sm' fontWeight='500'>
            {type === 'buyer' ? 'Awaiting accept' : 'Waiting to be accepted'} since {formatDistance(new Date(), new Date(contract.created))}
          </Text>
        }

        {/* Indicates if the contract has NOT been accepted. */}
        {!!contract.rejectedDate &&
          <Text color={notAcceptedColor} fontSize='sm' fontWeight='500'>
            {type === 'buyer' ? 'You rejected this contract' : 'This contract has been rejected'} since {formatDistance(new Date(), new Date(contract.rejectedDate))}
          </Text>
        }

      </Flex>

      {/* Shows the latest invoice price. */}
      {!!contract.price && !!contract.acceptedDate &&
        <Text ms='auto' color={textColor} fontSize='sm' me='6px' fontWeight='700'>
          {contract.price.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
        </Text>
      }

      {/* The button for showing the list of invoices. */}
      {contract.price !== undefined && contract.price !== null && !!contract.acceptedDate &&
        <Flex direction='row' justifyContent='flex-start' gap='2'>
          <CustomButton
            callback={() => loadPage(`/invoices/${contract.id}`)}
            color={acceptButtonColor}
            icon={MdPageview}
          />
        </Flex>
      }
    </Flex>
  );
}
