import { endOfDay, endOfMonth, startOfDay, startOfMonth } from "date-fns";
import moment from "moment";

import { HumanizerHelper } from "./Humanizer.helper";

/**
 * Helps with time conversions.
 */
export class TimeHelper extends HumanizerHelper {
  /**
   * Get only the date part from the
   * provided DATE object.
   */
  public getOnlyDate(theDate: Date | undefined): string {
    if (!theDate) {
      return "";
    }
    return moment(theDate).format("YYYY-MM-DD");
  }

  /**
   * This method is used to verify if the provided
   * string is a valid time in a date.
   * It returns true if it's valid otherwise
   * it should return false.
   *
   * @param time string
   * @returns boolean
   */
  public isValidTime(time: string): boolean {
    // Create a date object with today.
    const today = new Date();
    // Remove the time part of the today.
    const todayISO = today.toISOString();
    const indexOfT = todayISO.indexOf("T");
    const todayDate = todayISO.substring(0, indexOfT);
    // Add the provided time in the method.
    const todayDateWithTime = `${todayDate}${time}`;
    // Try to convert the resulting string to a timestamp
    const timestamp = Date.parse(todayDateWithTime);
    if (isNaN(timestamp) == false) {
      return true;
    }
    return false;
  }

  /**
   * Convert the given time to a
   * string.
   *
   * @param num number
   * @returns string
   */
  public convertToTime(num: number): string {
    const hours = Math.floor(num);
    return `${hours}:00`;
  }

  /**
   * Get the provided date together with
   * the current time attached to it.
   *
   * @param date
   * @returns
   */
  public getDateWithTime(date: Date): Date {
    // Get the currentDate but with time.
    const theDate = date.toISOString();
    const d = theDate.substring(0, theDate.indexOf("T"));
    const theTime = new Date().toISOString();
    const t = theTime.substring(theTime.indexOf("T") + 1);
    return new Date(`${d}T${t}`);
  }

  /**
   * It takes a date and a number of days to subtract from that date and returns a new date
   * @param {Date} date - Date - The date you want to subtract days from
   * @param {number} substractedDays - number of days to be substracted from the date
   * @returns A new date object with the date set to the date passed in plus 7 days.
   */
  public jumpBack7Days(): Date {
    return new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
  }

  /**
   * Check if the provided date is the
   * same as todays date.
   */
  public isToday(date: Date): boolean {
    return this.getOnlyDate(date) === this.getOnlyDate(moment().toDate());
  }

  /**
   * Format the provided date object to the
   * required interface format.
   *
   * @param date Date
   * @returns string
   */
  public formatDate(date: Date | number | undefined): string {
    return date ? moment(date).format("DD/MM/YYYY") : "";
  }

  /**
   * Get the start and end date that defines a
   * period of a month for the provided date.
   *
   * @param date Date
   * @returns Date[]
   */
  public getPeriodForProvidedDate(date: Date): Date[] {
    // !!moment(t.created).isSame(date, 'month')
    // const firstDay = moment(date).startOf('month').toDate();
    // const lastDay = moment(date).endOf('month').toDate();
    const firstDay = startOfMonth(date);
    const lastDay = endOfMonth(date);
    // var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    // var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return [firstDay, lastDay];
  }

  /**
   * Get the timestamp of the provided date
   * without the current hour and minute.
   *
   * @param date Date
   * @returns number
   */
  public getDateTimestampWithoutTime(date: Date): number {
    // Get the currentDate but with time.
    const theDate = date.toISOString();
    const d = theDate.substring(0, theDate.indexOf("T"));
    const theTime = new Date().toISOString();
    const t = theTime.substring(theTime.indexOf("T") + 1);
    return new Date(`${d}T${t}`).getTime();
  }
}
