import { InvoicesQueries } from "./InvoicesQueries";
import {
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  getDoc,
} from 'firebase/firestore';
import { IContractInvoice, IContractInvoicesContainer } from "./Dto";

export class InvoicesDb extends InvoicesQueries {

  constructor() {
    super();
  }

  /**
   * Get a list of contract invoices by the
   * document id.
   *
   * @returns 
   */
  public async getContractInvoicesContainerByDocumentId(id: string): Promise<IContractInvoicesContainer> {
    const docSnapshot = await getDoc(this.getDocById(id));
    const docData = docSnapshot.data();
    return this.mapContractInvoices(docData.contractId, docData.invoices, docSnapshot.id);
  }

  /**
   * Get a list of contract invoices by the
   * contract id.
   *
   * @returns 
   */
  public async getContractInvoicesContainerByContractId(contractId: string): Promise<IContractInvoicesContainer> {
    const docSnapshot = await getDocs(this.getQueryContractInvoicesByContractId(contractId));
    const containerSnapshot = docSnapshot.docs[0];
    if (!containerSnapshot) {
      return new Promise((reject: any) => reject(null));
    }
    const containerData = containerSnapshot.data();
    return this.mapContractInvoices(containerData.contractId, containerData.invoices, containerSnapshot.id);
  }

  /**
   * It takes a contractId, an array of invoices, and an optional documentId, and returns an object
   * with the contractId and invoices properties, and if the documentId is provided, it also includes
   * an id property
   * @param {string} contractId - string - the contract id
   * @param {IContractInvoice[]} invoices - IContractInvoice[]
   * @param {string} [documentId] - The document ID of the contract invoices container.
   * @returns An object with the contractId and invoices properties.
   */
  private mapContractInvoices(contractId: string, invoices: IContractInvoice[], documentId?: string): IContractInvoicesContainer {
    const result = {
      contractId,
      invoices,
    };
    return documentId ? { ...result, id: documentId } : result;
  }

  /**
   * > Check if a container already exists for the provided contract id. If it exists then update it
   * otherwise add it
   * @param {string} contractId - The id of the contract to add invoices to.
   * @param {IContractInvoice[]} invoices - IContractInvoice[]
   * @returns A promise.
   */
  public async addInvoicesForContract(contractId: string, invoices: IContractInvoice[]): Promise<any> {
    // Create a new list of invoices that have the savedDate filled.
    const invoicesWithSavedDate = invoices.map((invoice: IContractInvoice) => ({...invoice, savedDate: new Date().getTime()}));
    // Check if a container already exists for the provided contract id.
    const existingContainer = await this.getContractInvoicesContainerByContractId(contractId);
    // If it exists then update it otherwise add it.
    if (existingContainer) {
      this.updateInvoicesForContract(existingContainer, invoicesWithSavedDate);
    } else {
      return await addDoc(this.getCollection(), { contractId, invoices: invoicesWithSavedDate })
        .then((response: any) => !!response)
        .catch((error: any) => error);
    }
  }

  /**
   * It updates the invoices for a contract.
   * @param {string} id - the id of the contract
   * @param {IContractInvoice[]} invoices - IContractInvoice[]
   * @returns The updated document.
   */
  public async updateInvoicesForContract(existingContainer: IContractInvoicesContainer, invoices: IContractInvoice[]): Promise<any> {
    return await updateDoc(this.getDocById(existingContainer.id), { invoices });
  }

  /**
   * It deletes a contract invoices container from the database.
   * @param {string} id - the id of the document to be deleted
   * @returns a promise.
   */
  public async deleteContractInvoicesContainerInDb(id: string): Promise<any> {
    return await deleteDoc(this.getDocById(id));
  }

  /**
   * Get the existing list of invoices for that contract, get the new list of invoices without the ones
   * we want to delete, update the db with the new list of invoices.
   * @param {string} id - The id of the contract we want to update.
   * @param {string[]} invoiceIds - string[] - An array of invoice ids that we want to delete.
   * @returns The updated contract.
   */
  public async deleteContractInvoiceInDb(id: string, invoiceIds: string[]) {
    // Get the existing list of invoices for that contract.
    const contractInvoicesContainer = await this.getContractInvoicesContainerByDocumentId(id);
    // Get the new list of invoices without the ones we want to delete.
    const newInvoicesList = contractInvoicesContainer.invoices
      .filter((invoice: IContractInvoice) => !invoiceIds.includes(invoice.id));
    // Update the db with the new list of invoices.
    return await updateDoc(this.getDocById(id), { invoices: newInvoicesList });
  }
}