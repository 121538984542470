import PieCard from "components/card/PieCard";
import ModalWrapper from "components/modals/ModalWrapper";
import { TimeHelper } from "logic/Time.helper";
import { useEffect, useState } from "react";

import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";

import { IEntry } from "../../state/Tasks/TasksDTO";
import { TasksHelper } from "../../logic/tasks.helper";
import { useAppContext } from "../../state/useAppState";
import { useAuth } from "../../state/useAuthState";
import { useContracts } from "../../state/useContractsState";
import { TicketCard } from "./ticket-card";
import TicketGroup from "./ticket-group";

export enum TaskAddType {
  NORMAL,
  CLIPBOARD,
}
interface Props {
  entries: IEntry[];
}

const TicketList: React.FC<Props> = ({ entries }) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const { modalState, currentDate } = useAppContext();
  // Indicates if the user wants to add a new task.
  const [shouldAddTask, setShouldAddTask] = useState<TaskAddType>(null);
  // Holds the list of entries and refreshes when changes applied.
  const [list, setList] = useState<IEntry[]>([]);
  // Indicates if the currentDate matches with today's date.
  const [isToday, setIsToday] = useState<boolean>(false);
  // Get the currently selected date from the app context.
  const { currentContract } = useContracts();
  const { currentUser } = useAuth();
  // Helps with date and time methods.
  const timeHelper = new TimeHelper();
  // Holds the new task to be added.
  const [taskToAdd, setTaskToAdd] = useState<IEntry>(null);
  // Helps with different task related tasks.
  const tasksHelper = new TasksHelper();

  /**
   * Copy the label from the clipboard and set
   * it as current label for the new task.
   */
  const getClipboardContent = async () => {
    return await navigator.clipboard.readText().then((clipboard) => clipboard);
  };
  /**
   * Set the list of entries when any of them
   * is refreshed.
   */
  useEffect(() => {
    setList(
      entries.map((entry: IEntry) => ({
        ...entry,
        tempId: Math.floor(Math.random() * 100000),
      }))
    );
  }, [entries]);
  /**
   * Refresh some indicators when the current
   * date has been changed.
   */
  useEffect(() => {
    if (!currentDate) return;
    setIsToday(timeHelper.isToday(currentDate));
  }, [currentDate]);
  /**
   * Monitor if a new task should be added
   * and how it should be prepared.
   */
  useEffect(() => {
    if (
      shouldAddTask !== TaskAddType.NORMAL &&
      shouldAddTask !== TaskAddType.CLIPBOARD
    ) {
      setTaskToAdd(null);
      return;
    }
    // Get the new task data.
    let newTask = tasksHelper.getNewTask(
      timeHelper.getDateWithTime(currentDate).getTime(),
      currentContract.id,
      currentUser.uid
    );
    if (shouldAddTask === TaskAddType.NORMAL) {
      setTaskToAdd(newTask);
    }
    if (shouldAddTask === TaskAddType.CLIPBOARD) {
      getClipboardContent().then((content: any) => {
        newTask = {
          ...newTask,
          label: content.toString(),
        };
        setTaskToAdd(newTask);
      });
    }
  }, [shouldAddTask]);

  if (!currentContract) {
    return <></>;
  }

  return (
    <Box>
      <Flex
        px="25px"
        mb="8px"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text color={textColor} fontSize="22px" fontWeight="700">
          Logged tasks {isToday ? `today` : `on ${currentDate.toDateString()}`}
        </Text>
      </Flex>

      <Flex
        direction={{
          base: "column",
          sm: "column",
          md: "column",
          lg: "row",
          xl: "row",
        }}
        justifyContent="flex-start"
        alignItems="flex-start"
        gap="4"
      >
        <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "75%", xl: "75%" }}>
          <TicketGroup
            title="Todos"
            setShouldAddTask={setShouldAddTask}
            listOfTasks={list}
          >
            {list.map((entry: IEntry) => (
              <TicketCard key={entry.tempId} entry={entry} />
            ))}

            {taskToAdd && (
              <TicketCard
                addMode={true}
                setShouldAddTask={setShouldAddTask}
                entry={taskToAdd}
              />
            )}
          </TicketGroup>
        </Box>

        <Box
          pt="47px"
          position="sticky"
          maxW={{ base: "100%", sm: "100%", md: "100%", lg: "300px" }}
        >
          {list && <PieCard tasks={list} />}
        </Box>
      </Flex>

      {!!modalState && <ModalWrapper>{modalState.content}</ModalWrapper>}
    </Box>
  );
};

export default TicketList;
